'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.units.controller:AssetDataTypeCtrl

 # @description

###
class AssetDataTypeCtrl
  ### @ngInject ###
  constructor:(
    id
    $mdDialog
    AssetManager
    AssetDataManager
    $q
    $scope
    $log
  ) ->
    @progress = true
    @typeModel = {}
    @dataModel = {}
    @assetType = ""
    @typeFields = []
    @templateOptions = AssetManager.templateOptions
    init = =>
      @id = id

      AssetManager.one(@id).then ((data) =>
        @assetLabel = data.label
        @assetType = data.assetType
        @typeFields =
          [
            key: 'assetDataType'
            name: 'assetDataType'
            type: 'select'
            templateOptions:
              label: 'Data type'
              placeholder: 'Data type'
              required: true
              labelProp: "type"
              valueProp: "type"
              options: AssetDataManager.getList(@assetType)
          ]
      )


      @load = load
      @loadDataForm = loadDataForm
      @add = add
      @remove = remove
      @cancel = cancel
      @edit = edit

      @load()
      @loadDataForm()
    ##########

    add = ->
      if @editItem?
        @remove(@editItem).then(=>
          @editItem = undefined
        )
      @progress = true
      @dataModel.type = @typeModel.assetDataType
      $q.when(AssetManager.addData(@id, @dataModel))
        .then =>
          @typeModel = {}
          @dataFields = {}
          @dataModel = {}
          @typeForm.$setUntouched()
          @load()

    remove = (itemId) ->
      @progress = true
      $q.when(AssetManager.removeData(@id, itemId))
        .then =>
          @load()

    edit = (item) ->
      @editItem = item.id
      @items.splice(@items.indexOf(item),1)
      @typeModel.assetDataType = item.type

      if item.type == 'MapMarker'
        @dataModel.color = item.color
        @dataModel.marker = item.marker

      else if item.type == 'PoliceVehicle'
        @dataModel.installationType = item.installationType
        @dataModel.roofNumber = item.roofNumber
        @dataModel.isUnmarked = item.isUnmarked
        @dataModel.prioInstalled = item.prioInstalled

      else
        @dataModel.value = item.value

    load = ->
      AssetManager.one(@id)
        .then (data) =>
          @items = _.filter data.assetData ,(item) -> !item.deletedAt?
          @progress = false

    loadDataForm = =>
      if @typeModel.assetDataType?
        @dataFields = AssetManager.getDataFieldsByType(@typeModel.assetDataType)

    cancel = ->
      $mdDialog.cancel()

    $scope.$watch 'ctrl.typeModel.assetDataType', () ->
      loadDataForm()
    , true



    init()
angular
  .module('mundoAdmin.units')
  .controller 'AssetDataTypeCtrl', AssetDataTypeCtrl
